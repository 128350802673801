import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Heading, Radio, IconButton, css, Text, Select, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import TeklifTextField from '@solid-ui-components/ContentForm/TeklifTextField'
import FormInput3 from '@solid-ui-components/ContentForm/FormInput3'

import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpService from '../../../../../../site/src/service/httpService'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import ReactModal from 'react-modal'
import { FaRegStar, FaPlus, FaHandHoldingHeart, FaHandHoldingMedical } from 'react-icons/fa'
import Slider from '@solid-ui-blocks/Slider/Block01'
import loadingGif from '../../../assets/model-cizim-kutu-tasarimi2.gif'
import parse from 'html-react-parser'
import { setKtTokenSifre, getKtLogin, logOut, getKtToken } from '../../../../../../site/src/service/auth'
import loadsGif from '../../../assets/loads.gif'
import btnloading from '../../../assets/btnloading.gif'

import loadsBeyazGif from '../../../assets/loadsBeyaz.gif'
import indirPng from '../../../assets/online-paskage-pdf-die-cut.png'
import favoriPng from '../../../assets/favori-bicak-izi-pdf.png'
import noSearchPng from '../../../assets/no-search-die-cutting.png'

import { ModalContext } from '@solid-ui-components/Modal'
// import indirmeCss from './_bicakizi.css' //style={indirmeCss}
import Tabs from '@solid-ui-components/Tabs'
// import { Tab } from 'react-tabs'
import { TabsContext } from '@solid-ui-components/Tabs'
import { AiOutlineCloseCircle, AiFillCloseSquare, AiOutlineDelete } from 'react-icons/ai'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion'
import Icon from '@solid-ui-components/ContentIcon'
import Reveal from '@solid-ui-components/Reveal'
import toast, { Toaster } from 'react-hot-toast'
// import { useDispatch, useSelector } from 'react-redux'
// import MusteriEdit from '@solid-ui-blocks/Maliyet/Musteri'
// import LevhaKaliteEdit from '@solid-ui-blocks/Maliyet/Levha'
// import OfsetMakineEdit from '../OfsetMakine/OfsetMakine'
// import RenkEdit from '@solid-ui-blocks/Maliyet/Renk'
// import SivamaMakineEdit from '@solid-ui-blocks/Maliyet/SivamaMakine'
// import SelefonMakineEdit from '../SelefonMakine/SelefonMakine'
// import SelefonCinsiEdit from '../SelefonCinsi/SelefonCinsi'
// import LakCesidiEdit from '../LakCesidi/LakCesidi'
// import YaldizCesidiEdit from '../YaldizCesidi/YaldizCesidi'
// import KesimaMakineEdit from '@solid-ui-blocks/Maliyet/KesimMakine'
// import YapistirmaTuruEdit from '@solid-ui-blocks/Maliyet/YapistirmaTuru'
// import YapistirmaMakineEdit from '@solid-ui-blocks/Maliyet/YapistirmaMakine'
// import VadeEdit from '@solid-ui-blocks/Maliyet/Vade'
import Robot from '@solid-ui-blocks/Robot'
import Daktilo from '@solid-ui-components/Daktilo'

import FileEdit from '@solid-ui-blocks/Maliyet/FileUpl'
// import config from '../../../../../../site/src/core/config'
import config from '../../../../../../site/src/core/config'

const styles = {
    accordion: {
        '.accordion__button': {
            cursor: `pointer`,
            position: `relative`,
            outline: `none`,
            transition: `all 0.15s ease-in`
        },
        '.accordion__button:hover': {
            opacity: 0.75,
            '&::before': {
                borderColor: `beta`
            }
        },
        '.accordion__button::before': {
            content: `' '`,
            width: `10px`,
            height: `10px`,
            marginRight: `12px`,
            borderBottom: `3px solid currentColor`,
            borderLeft: `3px solid currentColor`,
            position: `absolute`,
            right: 4,
            top: `50%`,
            transform: `translate(0, -50%) rotate(45deg)`,
            transition: `all 0.15s ease-in`
        },
        '.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before': {
            transform: `translate(0, -50%) rotate(-45deg)`,
            transition: `transform 0.35s ease-in`
        },
        '[hidden]': {
            display: `none`
        },
        '.accordion__panel': {
            animation: `fadeIn 0.25s ease-in`
        },
        '@keyframes fadeIn': {
            '0%': {
                opacity: 0.5,
                transform: 'translate(0, -15%)'
            },
            '100%': {
                opacity: 1,
                transform: 'translate(0, 0%)'
            }
        }
    }
    ,
    listItem: {
        alignItems: `stretch`,
        justifyContent: `flex-start`
    },
    line: {
        position: `absolute`,
        bottom: `10px`,
        left: `50%`,
        width: `1px`,
        height: t => `calc(100% - ${t.sizes.icon.md + 20}px)`,
        transform: `translateX(-50%)`,
        zIndex: 1,
        bg: `omega`
    },
    number: {
        color: `white`,
        fontWeight: `body`,
        borderRadius: `full`,
        bg: `alpha`,
        size: `icon.md`,
        textAlign: `center`,
        p: 2,
        mb: 0
    },

    btnResimDegistir: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "white",
        border: "0.8px dotted red",
        fontSize: "0.87rem",

    },
    btnIslemler: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "#87f9bb",
        border: "0.8px dotted #204c07",
        fontSize: "0.87rem",
        color: '#204c07'
    },
    btnIslemSil: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "#FF6666",
        border: "0.8px dotted #FCAEAE",
        fontSize: "0.87rem",
        color: 'white'
    }
}

const UretimTakipFrm = ({ content: { SiparisId, FilterTip }, ...props }) => {

    const [ldg, setLdg] = useState(false);

    const [siparis, setsiparis] = useState(null);

    const [lst, setLst] = useState(null);

    const [toplamBilgi, settoplamBilgi] = useState("");


    function hesapla() {
        let adet = 0;
        lst.forEach(element => {
            if (document.getElementById('inp' + element.Id).checked == true) {
                adet += 1;
            }
        });

        settoplamBilgi("Seçili İş Emirleri: " + adet + " adet");

    }

    function satiriSec(sipNo) {

        lst.forEach(element => {


            document.getElementById('inp' + element.Id).checked = true;

            document.getElementById('btnSec' + element.Id).setAttribute("class", "btnGizle");
            document.getElementById('btnVazgec' + element.Id).setAttribute("class", "btnGoster");


            var adClas = "";
            if (siparis && siparis.SiparisNo == sipNo) {
                adClas = " satin-alma-bu-siparis";
            }


            document.getElementById('div' + element.Id).setAttribute("class", "secili-satin-alma" + adClas);



        });

        hesapla();
    }

    function satiriBirak(sipNo) {
        lst.forEach(element => {


            document.getElementById('inp' + element.Id).checked = false;

            document.getElementById('btnSec' + element.Id).setAttribute("class", "btnGoster");
            document.getElementById('btnVazgec' + element.Id).setAttribute("class", "btnGizle");

            var adClas = "";
            if (siparis && siparis.SiparisNo == sipNo) {
                adClas = " satin-alma-bu-siparis";
            }
            document.getElementById('div' + element.Id).setAttribute("class", "noselect-satin-alma" + adClas);


        });
        hesapla();
    }


    const [prm, setparametre] = useState(null);


    const [firtProcess, setfirtProcess] = useState("1");

    useEffect(async () => {
        if (typeof window !== 'undefined' && firtProcess == "1") {
            const ktLoginMiii = await getKtLogin();
            if (ktLoginMiii == true) {
                setfirtProcess("2");
                GetSiparis();
                GetUretimList();
            }
            else {
                window.location.assign("/");
            }
        }
        setLdg(true);
    }, [firtProcess]);

    async function GetUretimList(filter) {
        httpServiceToken.post("Locked/UretimList", { params: { Tip: filter } }).then((res) => {
            // console.log(res);
            if (res.DogruMu) {

                setLst(res.Data);

            } else {
                setLst(null);
                // setanaLst(null);

            }
        }).catch((err) => {
            console.log(err);
        });



    }

    async function GetSiparis() {
        if (SiparisId) {
            httpServiceToken.post("Locked/GetSiparis", { params: SiparisId }).then((res) => {
                // console.log(res);
                if (res.DogruMu) {
                    setsiparis(res.Data);
                    setTeklif(res.Data.Teklif);
                } else {

                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    useEffect(async () => {
        GetUretimList(FilterTip);
        settoplamBilgi("");
    }, [FilterTip]);

    const {
        token
    } = getKtToken()
    
    return (
        <Container>
            <Toaster />
            {ldg == false ? <div className='dv-l'>
                <Divider space="3" />
                <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
                <Divider space="5" />
            </div> :
                <form

                    method='POST'
                >
                    <Box>

                        <Box sx={{ textAlign: `center` }} >
                            <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
                                <Box sx={{
                                    flex: [`100%`, null, null, 1],
                                    width: '100%',
                                    px: [2, 2],
                                    py: [0, 1]
                                }}>
                                    <table style={{
                                        width: '100%',
                                        paddingBottom: "20px"
                                    }}>
                                        <tbody>
                                            <tr>
                                                <td style={{
                                                    width: '25%'

                                                }} >
                                                    {siparis && <Box
                                                        sx={styles.btnIslemler}
                                                        onClick=
                                                        {e => {
                                                            window.location.assign("/siparis-formu?I=" + siparis.Id)

                                                        }}>
                                                        Siparişe Geri Git
                                                    </Box>}
                                                </td>
                                                <td style={{
                                                    width: '45%',
                                                }} >
                                                    {siparis && <Box>
                                                        {siparis.SiparisNo} 'nolu sipariş iş durumu: {siparis.IsDurumText}
                                                    </Box>}
                                                </td>
                                                <td style={{
                                                    width: '30%',
                                                }} >

                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                    <Box sx={styles.accordion}>
                                        {lst && <Accordion allowMultipleExpanded={true} >

                                            <Container

                                                key={`item-${6575756867}`}
                                                variant='cards.primary'
                                                sx={{ '& + &': { mt: 3 } }}
                                            >
                                                <AccordionItem dangerouslySetExpanded={true}  >
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <Flex sx={{ alignItems: `center` }} p='3'>
                                                                <Icon size='xs' mr='3' p='2' round />
                                                                {FilterTip == 0 ? "Üretim Bekleyen İşler" : FilterTip == 1 ? "Üretimi Tamamlanan İşler" : "Müşteriye Teslim Edilen İşler"}
                                                            </Flex>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <Box p='2'>
                                                            <table border="1px" style={{ width: '100%', borderColor: "#fdfdfd", borderCollapse: 'collapse' }}>
                                                                <tbody>
                                                                    {lst.length > 0 && <tr>
                                                                        <th>Seç </th>
                                                                        <th style={{ fontSize: '12px', padding: '0.2rem' }}>Müşteri</th>
                                                                        <th style={{ fontSize: '12px', padding: '0.2rem' }}>İş</th>
                                                                        <th style={{ fontSize: '12px', padding: '0.2rem' }}>Ambalaj Adeti</th>
                                                                        <th style={{ fontSize: '12px', padding: '0.2rem' }}>Tabaka Adeti</th>
                                                                        <th style={{ fontSize: '12px', padding: '0.2rem' }}>İndir</th>

                                                                    </tr>
                                                                    }
                                                                    {lst.map(({ Id, MusteriAdi, IsAdi, Olcu, TalepAdet, TabakaAdet, DownLink, SiparisNo }, ind) => {


                                                                        let klmId = Id;
                                                                        var adClas = "";
                                                                        if (siparis && siparis.SiparisNo == SiparisNo) {
                                                                            adClas = " satin-alma-bu-siparis";
                                                                        }
                                                                        return (
                                                                            <tr key={`item-${ind}`} id={"div" + klmId} className={"noselect-satin-alma" + adClas} onClick={(e) => {
                                                                                if (e.currentTarget.className.includes("noselect-satin-alma")) {
                                                                                    satiriSec(SiparisNo);

                                                                                } else {
                                                                                    satiriBirak(SiparisNo);

                                                                                }
                                                                            }}>
                                                                                <td>
                                                                                    <Box sx={{ display: "none" }}>
                                                                                        <input type="checkbox" name={"nm" + klmId} id={'inp' + klmId} />
                                                                                    </Box>
                                                                                    <Box id={"btnVazgec" + klmId} className="btnGizle"
                                                                                        onClick={() => {
                                                                                            satiriBirak(SiparisNo);


                                                                                        }}  >Vazgeç</Box>
                                                                                    <Box id={"btnSec" + klmId} sx={{ cursor: "pointer" }}
                                                                                        onClick={() => {
                                                                                            satiriSec(SiparisNo);

                                                                                        }}>Seç</Box>

                                                                                </td>
                                                                                <td style={{ fontSize: '12px', padding: '0.2rem' }}>{MusteriAdi}</td>
                                                                                <td style={{ fontSize: '12px', padding: '0.2rem' }}> {IsAdi} </td>
                                                                                <td style={{ fontSize: '12px', padding: '0.2rem' }}>{TalepAdet}</td>
                                                                                <td style={{ fontSize: '12px', padding: '0.2rem' }}>{TabakaAdet}</td>
                                                                                <td style={{ fontSize: '12px', padding: '0.2rem' }}>
                                                                                     <Box id={"btnSec" + klmId} sx={{ cursor: "pointer",textDecoration:"underline" }}
                                                                                    onClick={() => {
                                                                                        document.getElementById("ifRam").removeAttribute("src");
                                                                                        document.getElementById("ifRam").setAttribute("src", config.serviceBaseAddress + DownLink.replace("_token_",token));
                                                                                    }}>İş Emri İndir</Box></td>

                                                                            </tr>
                                                                        )
                                                                    }
                                                                    )
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </Box>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Container>

                                        </Accordion>
                                        }
                                    </Box>
                                    {(
                                        lst?.length == 0
                                        &&
                                        <div className='no-search'>
                                            <Divider space="2" />
                                            <img src={noSearchPng} alt="online kutu tasarımı" style={{ width: "200px", objectFit: "contain" }} />
                                            {FilterTip == 0 && <div>Bekleyen üretim listesinde bekleyen her hangi bir kayıt bulunamadı</div>}
                                            {FilterTip == 1 && <div>Üretilen siparişler listesinde bekleyen her hangi bir kayıt bulunamadı</div>}
                                            {FilterTip == 2 && <div>Teslim edilen siparişler listesinde bekleyen her hangi bir kayıt bulunamadı</div>}
                                            <Divider space="5" />
                                        </div>
                                    )}
                                    {FilterTip == 0 && <table style={{
                                        width: '100%',
                                        paddingBottom: "20px"
                                    }}>
                                        <tbody>
                                            <tr>

                                                <td style={{
                                                    width: '30%'

                                                }} >

                                                </td>
                                                <td style={{
                                                    width: '35%'

                                                }} >
                                                    {lst?.length == 0 ? "" : toplamBilgi}
                                                </td>
                                                <td style={{
                                                    width: '35%',
                                                }} >
                                                    {lst && lst.length > 0 && <Box
                                                        sx={styles.btnIslemler}
                                                        onClick=
                                                        {e => {
                                                            let kalemler = [];
                                                            lst.forEach(element => {
                                                                if (document.getElementById('inp' + element.Id).checked == true) {
                                                                    kalemler.push(element);
                                                                }
                                                            });
                                                            httpServiceToken.post("Locked/SeciliIslerinUretimiTamamlandi", { params: kalemler }).then((res) => {

                                                                if (res.DogruMu) {

                                                                    toast.success(res.Mesaj, {
                                                                        duration: 5000,
                                                                        position: 'top-left'
                                                                    });

                                                                    GetUretimList();
                                                                    GetSiparis();

                                                                } else {
                                                                    toast.error(res.Mesaj, {
                                                                        duration: 5000,
                                                                        position: 'top-left'
                                                                    });
                                                                }
                                                            }).catch((err) => {
                                                                //console.log(err);
                                                            });

                                                        }}>
                                                        Seçili İşlerin Üretimi Tamamlandı
                                                    </Box>
                                                    }
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>
                                    }
                                    {FilterTip == 1 && <table style={{
                                        width: '100%',
                                        paddingBottom: "20px"
                                    }}>
                                        <tbody>
                                            <tr>

                                                <td style={{
                                                    width: '30%'

                                                }} >

                                                </td>
                                                <td style={{
                                                    width: '35%'

                                                }} >
                                                    {lst?.length == 0 ? "" : toplamBilgi}
                                                </td>
                                                <td style={{
                                                    width: '35%',
                                                }} >
                                                    {lst && lst.length > 0 && <Box
                                                        sx={styles.btnIslemler}
                                                        onClick=
                                                        {e => {
                                                            let kalemler = [];
                                                            lst.forEach(element => {
                                                                if (document.getElementById('inp' + element.Id).checked == true) {
                                                                    kalemler.push(element);
                                                                }
                                                            });
                                                            httpServiceToken.post("Locked/SeciliIslerTeslimEdildi", { params: kalemler }).then((res) => {

                                                                if (res.DogruMu) {

                                                                    toast.success(res.Mesaj, {
                                                                        duration: 5000,
                                                                        position: 'top-left'
                                                                    });
                                                                    GetUretimList();
                                                                    GetSiparis();
                                                                } else {
                                                                    toast.error(res.Mesaj, {
                                                                        duration: 5000,
                                                                        position: 'top-left'
                                                                    });
                                                                }
                                                            }).catch((err) => {
                                                                //console.log(err);
                                                            });
                                                        }}>
                                                        Seçili İşler Müşteriye Teslim Edildi
                                                    </Box>
                                                    }
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>
                                    }

                                </Box>
                            </Flex>
                        </Box>
                    </Box>

                </form >
            }
            <div id="ifRa" style={{ display: "none", height: "0px" }}>
                <iframe id="ifRam" ></iframe>
            </div>
        </Container>

    )
}

export default WithDefaultContent(UretimTakipFrm)


